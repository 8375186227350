







































































































import Vue from 'vue'
import { mapState } from 'vuex'
import { Component } from 'vue-property-decorator'

@Component({
  computed: mapState(['isDarkMode']),
})
export default class DonateDialog extends Vue {
  public copyError: string = ''
  public copyDone: boolean = false
  public isDarkMode!: boolean
  public qrDialog: boolean = false
  public qrSrc: string = ''
  public visible: boolean = false
  public cards = [
    { title: 'paypal', src: require('../../assets/paypal.png') },
    { title: 'ko-fi', src: require('../../assets/Ko-fi_Logo_RGB.png') },
    { title: 'paymaya', src: require('../../assets/paymaya.png') },
  ]
  public wallets = {
    btc: '3MmJbNGepzbia2zARU7P6G1dYMVxvH8tQN',
    xrp: { address: 'rU2mEJSLqBRkYLVTv55rFTgQajkLTnT6mA', tag: '229138' },
    bch: 'pr39uwqhg5tz46r82kemg2vsg0syqf7tw5vqu40cm3',
  }

  public created() {
    this.$bus.$on('show-donate-dialog', () => this.visible = true)
  }

  public copyHandler(data: string) {
    if (!navigator.clipboard) {
      const el = document.createElement('textarea')
      el.value = data
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()

      try {
        const result = document.execCommand('copy')
        this.copyError = result ? '' : 'Couldn\'t copy'
        this.copyDone = true
        document.body.removeChild(el)
      } catch (err) {
        this.copyError = err
        this.copyDone = true
      }

      return
    }

    navigator.clipboard.writeText(data)
      .then(() => this.copyError = '')
      .catch((err) => this.copyError = err)
      .finally(() => this.copyDone = true)
  }

  public donateHandler(method: string) {
    switch (method) {
      case 'paypal':
        this.openLink('https://paypal.me/jareddantis')
        break
      case 'ko-fi':
        this.openLink('https://ko-fi.com/dantis')
        break
      case 'paymaya':
        this.qrSrc = require('../../assets/paymaya.svg')
        this.qrDialog = true
        break
      default:
        this.qrSrc = require(`../../assets/${method}.png`)
        this.qrDialog = true
    }
  }

  private openLink(link: string) {
    window.open(link, '_blank', 'noopener')
    this.visible = false
  }
}
